export default [
    {
        path: '/subitem',
        name: 'Subitem',
        component: () => import('./Subitem.vue')
    },
    {
        path: '/subitem/create',
        name: 'SubitemCreate',
        component: () => import('./Create.vue')
    },
    {
        path: '/subitem/edit/:id',
        name: 'SubitemEdit',
        component: () => import('./Edit.vue')
    }
]